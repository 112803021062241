<template>
  <svg viewBox="0 0 20 21">
    <path
      d="M5.83333 5.5V3C5.83333 2.77899 5.92113 2.56703 6.07741 2.41075C6.23369 2.25447 6.44565 2.16667 6.66667 2.16667H16.6667C16.8877 2.16667 17.0996 2.25447 17.2559 2.41075C17.4122 2.56703 17.5 2.77899 17.5 3V14.6667C17.5 14.8877 17.4122 15.0996 17.2559 15.2559C17.0996 15.4122 16.8877 15.5 16.6667 15.5H14.1667V18C14.1667 18.46 13.7917 18.8333 13.3275 18.8333H3.33917C3.22927 18.834 3.12033 18.8129 3.0186 18.7714C2.91687 18.7298 2.82436 18.6685 2.74638 18.5911C2.6684 18.5136 2.60649 18.4216 2.56421 18.3201C2.52193 18.2187 2.50011 18.1099 2.5 18L2.5025 6.33333C2.5025 5.87333 2.8775 5.5 3.34167 5.5H5.83333ZM4.16917 7.16667L4.16667 17.1667H12.5V7.16667H4.16917ZM7.5 5.5H14.1667V13.8333H15.8333V3.83333H7.5V5.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DuplicateIcon",
};
</script>
